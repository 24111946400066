<template>
  <b-container>
    <b-row class="mb-2">
      <b-col>
        <h2>{{ $t("home.admin-title", { userName: userName }) }}</h2>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import auth from "@/services/auth.js";

export default {
  computed: {
    userName: () => auth.userName()
  }
};
</script>
